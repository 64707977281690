html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.embla {
  overflow: hidden;
  width: 100%;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
}
@media (min-width: 768px) {
  .embla {
    width: 90%;
  }
}
@media (min-width: 1024px) {
  .embla {
    width: 80%;
  }
}